












import IconFire from '@/components/UI/icons/IconFire.vue'
import { displayedDate } from '@/utils'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    IconFire,
  },
})
export default class Deadline extends Vue {
  @Prop({ type: String, required: true }) readonly date!: string
  @Prop({ type: Boolean }) readonly isDone?: boolean
  @Prop({ type: Boolean }) readonly isExpired?: boolean

  private get color (): string {
    // TODO: change colors
    if (this.isDone) return 'faded'
    if (this.isExpired) return 'negative'
    return 'main-accent'
  }

  private get colorClass (): string {
    return `text-${this.color}`
  }

  private get text (): string | undefined {
    const date = new Date(this.date)
    return displayedDate(date)
  }
}
